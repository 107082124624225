import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, Tabs, Tab } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Add, ArrowUpward, ArrowDownward, CompareArrows } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { accountService } from '../services/accountService';
import { formatCurrency } from '../utils/currencyUtils';
import DepositForm from '../components/AccountDashboard/DepositForm';
import WithdrawalForm from '../components/AccountDashboard/WithdrawalForm';
import TransferForm from '../components/AccountDashboard/TransferForm';
import NewAccountForm from '../components/AccountDashboard/NewAccountForm';
import FilterableDataGrid from '../components/common/FilterableDataGrid';
import useDocumentTitle from '../hooks/useDocumentTitle';

const AccountDashboard = () => {
  useDocumentTitle('Account');
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDepositFormOpen, setIsDepositFormOpen] = useState(false);
  const [isWithdrawalFormOpen, setIsWithdrawalFormOpen] = useState(false);
  const [isTransferFormOpen, setIsTransferFormOpen] = useState(false);
  const [isNewAccountFormOpen, setIsNewAccountFormOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    fetchAccounts();
    fetchDashboardData();
    fetchTransactions();
  }, []);

  useEffect(() => {
    const filtered = accounts.filter(account => 
      account.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      account.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      account.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAccounts(filtered);
  }, [searchTerm, accounts]);

  const fetchAccounts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await accountService.fetchAccounts();
      setAccounts(data.results);
      setFilteredAccounts(data.results);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setError('Failed to fetch accounts. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDashboardData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const allAccountsData = await accountService.fetchAllAccounts();
      console.log('Fetched all accounts data:', allAccountsData);
     
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to fetch dashboard data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTransactions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const params = {
          transaction_type: 'transfer',
      };
      const data = await accountService.fetchTransactions();
      const transactionsWithDateObjects = data.results.map(transaction => ({
        ...transaction,
        date: new Date(transaction.date)
      }));
      setTransactions(transactionsWithDateObjects);
      console.log("Transaction is: ", transactionsWithDateObjects);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setError('Failed to fetch transactions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const accountColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Account Name', width: 200 },
    { field: 'type', headerName: 'Type', width: 130 },
    { field: 'category', headerName: 'Category', width: 130 },
    { 
      field: 'balance', 
      headerName: 'Balance', 
      width: 150, 
      type: 'number',
      renderCell: (params) => (
        <Typography>{formatCurrency(params.value)}</Typography>
      ),
    },
  ];

  const transactionColumns = [
    { 
      field: 'date', 
      headerName: 'Date', 
      width: 150, 
      type: 'date',
    },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'transaction_number', headerName: 'Transaction Number', width: 180 },
    { field: 'transaction_type', headerName: 'Transaction Type', width: 150 },
    { 
      field: 'amount', 
      headerName: 'Amount', 
      width: 150, 
      type: 'number',
      renderCell: (params) => (
        <Typography>{formatCurrency(params.value)}</Typography>
      ),
    },
  ];

  const handleDepositCreated = () => {
    fetchAccounts();
    fetchDashboardData();
    fetchTransactions();
  };

  const handleWithdrawalCreated = () => {
    fetchAccounts();
    fetchDashboardData();
    fetchTransactions();
  };

  const handleTransferCreated = () => {
    fetchAccounts();
    fetchDashboardData();
    fetchTransactions();
  };

  const handleAccountCreated = () => {
    fetchAccounts();
    fetchDashboardData();
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  if (isLoading) {
    return <Typography><FormattedMessage id="common.loading" /></Typography>;
  }

  if (error) {
    return <Typography color="error"><FormattedMessage id="common.error" values={{ error }} /></Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        <FormattedMessage id="account.dashboard.title" />
      </Typography>
      
      <Box sx={{ mb: 3 }}>
        <Button variant="contained" startIcon={<Add />} sx={{ mr: 1 }} onClick={() => setIsNewAccountFormOpen(true)}>
          <FormattedMessage id="account.new.button" />
        </Button>
        <Button variant="outlined" startIcon={<ArrowUpward />} sx={{ mr: 1 }} onClick={() => setIsDepositFormOpen(true)}>
          <FormattedMessage id="account.deposit.button" />
        </Button>
        <Button variant="outlined" startIcon={<ArrowDownward />} sx={{ mr: 1 }} onClick={() => setIsWithdrawalFormOpen(true)}>
          <FormattedMessage id="account.withdrawal.button" />
        </Button>
        <Button variant="outlined" startIcon={<CompareArrows />} onClick={() => setIsTransferFormOpen(true)}>
          <FormattedMessage id="account.transfer.button" />
        </Button>
      </Box>


      <Tabs value={currentTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label={<FormattedMessage id="account.tab.accounts" />} />
        <Tab label={<FormattedMessage id="account.tab.transactions" />} />
      </Tabs>

      {currentTab === 0 && (
        <>
          <TextField
            fullWidth
            variant="outlined"
            label={<FormattedMessage id="account.search.label" />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={filteredAccounts}
              columns={accountColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Box>
        </>
      )}

      {currentTab === 1 && (
        <FilterableDataGrid
          rows={transactions}
          columns={transactionColumns}
          loading={isLoading}
          dateField="date"
        />
      )}

      <NewAccountForm
        open={isNewAccountFormOpen}
        handleClose={() => setIsNewAccountFormOpen(false)}
        onAccountCreated={handleAccountCreated}
      />

      <DepositForm
        open={isDepositFormOpen}
        handleClose={() => setIsDepositFormOpen(false)}
        accounts={accounts}
        onDepositCreated={handleDepositCreated}
      />

      <WithdrawalForm
        open={isWithdrawalFormOpen}
        handleClose={() => setIsWithdrawalFormOpen(false)}
        accounts={accounts}
        onWithdrawalCreated={handleWithdrawalCreated}
      />

      <TransferForm
        open={isTransferFormOpen}
        handleClose={() => setIsTransferFormOpen(false)}
        accounts={accounts}
        onTransferCreated={handleTransferCreated}
      />
    </Box>
  );
};

export default AccountDashboard;