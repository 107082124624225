import { axiosInstance, API_ENDPOINTS } from '../api/api';

export const inventoryService = {
  createTransfer: async (transferData) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.stocktransfer, transferData);
      return response.data;
    } catch (error) {
      console.error('Error creating stock transfer:', error);
      throw error;
    }
  },

  getTransfers: async (params = {}) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.stocktransfer);
      console.log("IN?",response);
      // return {
      //   results: response.data.results,
      //   next: response.data.next,
      //   previous: response.data.previous,
      //   count: response.data.count
      // };
      return response.data.results;
    } catch (error) {
      console.error('Error fetching stock transfers:', error);
      throw error;
    }
  },


  getTransferById: async (id) => {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.stocktransfer}${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching stock transfer:', error);
      throw error;
    }
  },

  updateTransfer: async (id, updateData) => {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.stocktransfer}${id}/`, updateData);
      return response.data;
    } catch (error) {
      console.error('Error updating stock transfer:', error);
      throw error;
    }
  },

  deleteTransfer: async (id) => {
    try {
      const response = await axiosInstance.delete(`${API_ENDPOINTS.stocktransfer}${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error deleting stock transfer:', error);
      throw error;
    }
  },

  // Stock Adjustment Functions
  createAdjustment: async (adjustmentData) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.stockadjustment, adjustmentData);
      return response.data;
    } catch (error) {
      console.error('Error creating stock adjustment:', error);
      throw error;
    }
  },

  getAllAdjustments: async (params = {}) => {
    let allAdjustments = [];
    let nextPage = `${API_ENDPOINTS.stockadjustment}?${new URLSearchParams(params).toString()}`;

    try {
      while (nextPage) {
        const response = await axiosInstance.get(nextPage);
        allAdjustments = [...allAdjustments, ...response.data.results];
        nextPage = response.data.next;
      }
      return allAdjustments;
    } catch (error) {
      console.error('Error fetching all stock adjustments:', error);
      throw error;
    }
  },

  getAdjustments: async (params = {}) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.stockadjustment, { params });
      return {
        results: response.data.results,
        next: response.data.next,
        previous: response.data.previous,
        count: response.data.count
      };
    } catch (error) {
      console.error('Error fetching stock adjustments:', error);
      throw error;
    }
  },

  getAdjustmentById: async (id) => {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.stockadjustment}/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching stock adjustment:', error);
      throw error;
    }
  },

  updateAdjustment: async (id, updateData) => {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.stockadjustment}/${id}`, updateData);
      return response.data;
    } catch (error) {
      console.error('Error updating stock adjustment:', error);
      throw error;
    }
  },
  deleteAdjustment: async (id) => {
    try {
      const response = await axiosInstance.delete(`${API_ENDPOINTS.stockadjustment}/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting stock adjustment:', error);
      throw error;
    }
  },
  fetchInventoryByWarehouse: async (warehouse_id) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.inventory,{
        params: {
          warehouse_id,
          page_size: 1000,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching inventory:', error);
      throw error;
    }
  },

  fetchInventoryByProduct: async (product_id) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.inventory,{
        params: {
          product_id,
          page_size: 1000, 
        },
      });
      // console.log("Inventory is: ", response.data.results);
      return response.data.results;
    } catch (error) {
      console.error('Error fetching inventory:', error);
      throw error;
    }
  }
};