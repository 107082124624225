// src/services/orderService.js

import { axiosInstance, API_ENDPOINTS } from '../api/api';

export const orderService = {
  async fetchOrderDetails(id) {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.orders}${id}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching order details:', error);
      throw error;
    }
  },

  async createOrder(orderData) {
    try {
      const formattedOrderData = {
        ...orderData,
        items: orderData.items.map(item => ({
          ...item,
          product: item.product
        }))
      };
      // console.log(formattedOrderData);
      const response = await axiosInstance.post(API_ENDPOINTS.orders, formattedOrderData);
      return response.data;
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    }
  },

  async updateOrder(id, orderData) {
    try {
      // console.log('Sending order data:', JSON.stringify(orderData, null, 2));
      const formattedOrderData = {
        ...orderData,
        items: orderData.items.map(item => ({
          ...item,
        }))
      };
      // console.log('Order yg masuk is?', formattedOrderData);
      const response = await axiosInstance.put(`${API_ENDPOINTS.orders}${id}/`, formattedOrderData);
      return response.data;
    } catch (error) {
      console.error('Error updating order:', error);
      throw error;
    }
  },

  async deleteOrder(id) {
    try {
      await axiosInstance.delete(`${API_ENDPOINTS.orders}${id}/`);
    } catch (error) {
      console.error('Error deleting order:', error);
      throw error;
    }
  },

  async searchOrders(searchTerm, page = 1, pageSize = 10) {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.orders, {
        params: {
          include_items: false,
          search: searchTerm,
          page,
          page_size: pageSize,
        },
      });
      return {
        results: response.data.results,
        next: response.data.next,
        previous: response.data.previous,
        count: response.data.count,
      };
    } catch (error) {
      console.error('Error searching orders:', error);
      throw error;
    }
  },

  async exportOrders(startDate, endDate) {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.orders}export/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      console.error('Error exporting orders:', error);
      throw error;
    }
  },
};