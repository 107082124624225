import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { inventoryService } from '../services/inventoryService';
import { 
  Card, 
  CardContent, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Grid,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import useSessionStorage from '../hooks/useSessionStorage';

const WarehouseTransferDetailView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transferDetail, setTransferDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [warehouses, setWarehouses] = useSessionStorage('warehouses',[]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchTransferDetail = async () => {
      if (!id) {
        setError('No transfer ID provided');
        setLoading(false);
        return;
      }

      try {
        const data = await inventoryService.getTransferById(id);
        console.log("warehouses is: ", warehouses);
        const toWarehouse =  warehouses.find(warehouse => warehouse.id === data.to_warehouse);
        const fromWarehouse = warehouses.find(warehouse => warehouse.id === data.from_warehouse);
        console.log("To warehouse",toWarehouse);
        setTransferDetail({...data,
          to_warehouse_name: toWarehouse.name|| "toWarehouse",
          from_warehouse_name: fromWarehouse.name|| "fromWarehouse"
        });

        console.log("Transfer data is: ",transferDetail);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch transfer details');
        setLoading(false);
      }
    };

    fetchTransferDetail();
  }, [id]);

  const handleEdit = () => {
    navigate(`/warehouse-transfers/edit/${id}`);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await inventoryService.deleteTransfer(id);
      navigate('/warehouse-transfers');
    } catch (err) {
      setError('Failed to delete transfer');
    }
    setOpenDeleteDialog(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!transferDetail) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>No transfer details found</Typography>
      </Box>
    );
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5" gutterBottom>
            Warehouse Transfer Detail
          </Typography>
          <Box>
            <Button
              startIcon={<EditIcon />}
              onClick={handleEdit}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              Edit
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
              color="error"
              variant="outlined"
            >
              Delete
            </Button>
          </Box>
        </Box>
        
        <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">Transfer Date:</Typography>
              <Typography variant="body1">{new Date(transferDetail.transfer_date).toLocaleDateString()}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">Transfer No:</Typography>
              <Typography variant="body1">{transferDetail.transfer_number}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">From Warehouse:</Typography>
              <Typography variant="body1">{transferDetail.from_warehouse_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">To Warehouse:</Typography>
              <Typography variant="body1">{transferDetail.to_warehouse_name}</Typography>
            </Grid>
          </Grid>
        </Paper>

        <TableContainer component={Paper} elevation={2}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell align="right">Transfer Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transferDetail.items?.map((item, index) => (
                <TableRow key={index} hover>
                  <TableCell>{item.product?.sku || 'N/A'}</TableCell>
                  <TableCell>{item.product?.name || item.item_name || 'N/A'}</TableCell>
                  <TableCell align="right">{item.transfer_quantity || item.quantity || 0}</TableCell>
                </TableRow>
              )) || (
                <TableRow>
                  <TableCell colSpan={4} align="center">No items found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <Dialog
        fullScreen={fullScreen}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this warehouse transfer? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default WarehouseTransferDetailView;