import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Button, 
  Tab, 
  Tabs, 
  Typography,
  Paper,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { SwapHoriz as SwapHorizIcon, Edit as EditIcon, Add as AddIcon, Visibility as VisibilityIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { inventoryService } from '../services/inventoryService';
import { warehouseService } from '../services/warehouseService';
import useSessionStorage from '../hooks/useSessionStorage';


const WarehouseDashboard = () => {
  const [tabValue, setTabValue] = useState(0);
  const [stockTransferData, setStockTransferData] = useState([]);
  const [stockAdjustmentData, setStockAdjustmentData] = useState([]);
  const [warehouseLocationData, setWarehouseLocationData] = useState([]);
  const [warehouses, setWarehouses] = useSessionStorage('warehouses', []);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [transfers, adjustments] = await Promise.all([
        inventoryService.getTransfers(),
        inventoryService.getAllAdjustments()
      ]);
      let warehousesData = warehouses;
      console.log("Warehouses is?",warehouses);
      if (warehouses.length === 0) {
        console.log("Masuk?");
        warehousesData = await warehouseService.fetchAllWarehouses();
        setWarehouses(warehousesData);
      }
      setStockTransferData(transfers.filter(transfer => transfer.id != null).map(transfer => {
        const fromWarehouse = warehouses.find(warehouse => warehouse.id === transfer.from_warehouse);
        const toWarehouse = warehouses.find(warehouse => warehouse.id === transfer.to_warehouse);
        
        return {
          ...transfer,
          id: transfer.id,
          transfer_date: formatDate(transfer.transfer_date),
          from_warehouse_name: fromWarehouse ? fromWarehouse.name : '',
          to_warehouse_name: toWarehouse ? toWarehouse.name : ''
        };
      }));

      setStockAdjustmentData(adjustments.map(adjustment => ({
        ...adjustment,
        id: adjustment.id,
        adjustment_date: formatDate(adjustment.adjustment_date),
        warehouse_name: adjustment.warehouse?.name || ''
      })));

      setWarehouseLocationData(warehouses.map(warehouse => ({ 
        ...warehouse, 
        id: warehouse.id
      })));
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDelete = useCallback(async (service, id) => {
    try {
      await service(id);
      fetchData();
    } catch (error) {
      console.error('Error deleting item:', error);
      setError('Failed to delete item. Please try again.');
    }
  }, [fetchData]);

  const handleViewTransfer = useCallback((id) => {
    console.log('Viewing transfer with ID:', id);
    if (id != null && id !== undefined) {
      navigate(`/warehouse/transfer/${id}`);
    } else {
      console.error('Invalid transfer ID:', id);
      setError('Invalid transfer ID');
    }
  }, [navigate]);

  const columns = {
    stockTransfer: [
      { field: 'id', headerName: 'ID', flex: 0.5 },
      { field: 'transfer_date', headerName: 'Date', flex: 1 },
      { field: 'transfer_number', headerName: 'Transfer #', flex: 1 },
      { field: 'from_warehouse_name', headerName: 'From', flex: 1 },
      { field: 'to_warehouse_name', headerName: 'To', flex: 1 },
      { field: 'status', headerName: 'Status', flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => (
          <>
            <IconButton
              onClick={() => handleViewTransfer(params.row.id)}
              color="primary"
              size="small"
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(inventoryService.deleteTransfer, params.row.id)}
              color="error"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ],
    stockAdjustment: [
      { field: 'id', headerName: 'Adjustment #', flex: 1 },
      { field: 'adjustment_date', headerName: 'Date', flex: 1 },
      { field: 'adjustment_type', headerName: 'Type', flex: 1 },
      { field: 'reason', headerName: 'Reason', flex: 1 },
      { field: 'warehouse_name', headerName: 'Warehouse', flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => (
          <>
            <IconButton
              component={Link}
              to={`/warehouse/adjustment/${params.row.id}`}
              color="primary"
              size="small"
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              component={Link}
              to={`/warehouse/adjustment/${params.row.id}/edit`}
              color="primary"
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(inventoryService.deleteAdjustment, params.row.id)}
              color="error"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ],
    warehouseLocation: [
      { field: 'code', headerName: 'Code', flex: 1 },
      { field: 'name', headerName: 'Name', flex: 1 },
      { field: 'contact_number', headerName: 'Contact', flex: 1 },
      { field: 'status', headerName: 'Status', flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => (
          <>
            <IconButton
              component={Link}
              to={`/warehouse/${params.row.id}/edit`}
              color="primary"
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(warehouseService.deleteWarehouse, params.row.id)}
              color="error"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ],
  };

  const buttons = [
    { label: 'warehouse.transfer.button', icon: SwapHorizIcon, to: '/warehouse/transfer' },
    { label: 'stock.adjustment.button', icon: EditIcon, to: '/warehouse/adjustment' },
    // { label: 'warehouse.location.create.button', icon: AddIcon, to: '/warehouse/location' },
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Warehouse Dashboard
      </Typography>
      
      <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
        {buttons.map((button, index) => (
          <Button 
            key={index}
            variant="contained" 
            startIcon={<button.icon />}
            component={Link}
            to={button.to}
          >
            <FormattedMessage id={button.label} />
          </Button>
        ))}
      </Box>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="warehouse tabs">
          <Tab label="Stock Transfer" />
          <Tab label="Stock Adjustment" />
          {/* <Tab label="Warehouse Locations" /> */}
        </Tabs>
      </Paper>

      {[stockTransferData, stockAdjustmentData, warehouseLocationData].map((data, index) => (
        tabValue === index && (
          <DataGrid
            key={index}
            rows={data}
            columns={columns[Object.keys(columns)[index]]}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            autoHeight
            onRowClick={(params) => console.log('Row data:', params.row)}
          />
        )
      ))}

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WarehouseDashboard;