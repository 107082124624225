// productUtils.js
import React from 'react';

export const productInfo = {
    sku: 'PRD001',
    name: 'Sample Product',
    dateCreated: '2023-01-01',
    lastReceived: '2023-06-15',
  };
  
  export const financialInfo = {
    buyPrice: 50,
    salesPrice: 100,
    buyAccount: '1001-001',
    salesAccount: '4001-001',
    averagePrice: 55,
    profitMargin: 45,
  };
  
  export const stockTransactions = [
    { id: 1, date: '2023-06-15', transactionNo: 'TRX001', quantity: 100, type: 'Inbound' },
    { id: 2, date: '2023-06-20', transactionNo: 'TRX002', quantity: -50, type: 'Outbound' },
  ];
  
  export const warehouseQuantities = [
    { id: 1, name: 'Warehouse A', quantity: 30 },
    { id: 2, name: 'Warehouse B', quantity: 20 },
  ];
  
  export const incomingStock = [
    { id: 1, procureNumber: 'PO001', date: '2023-07-01', expectedArrival: '2023-07-10', quantity: 50 },
    { id: 2, procureNumber: 'PO002', date: '2023-07-05', expectedArrival: '2023-07-15', quantity: 75 },
  ];
  
  export const salesTrend = [
    { month: 'Jan', sales: 100 },
    { month: 'Feb', sales: 120 },
    { month: 'Mar', sales: 110 },
    { month: 'Apr', sales: 130 },
    { month: 'May', sales: 90 },
    { month: 'Jun', sales: 110 },
  ];
  
  export const stockTransactionColumns = [
    { field: 'date', headerName: 'Date', width: 120 },
    { field: 'transactionNo', headerName: 'Transaction No', width: 150, 
      renderCell: (params) => (
        <a href={`/transaction/${params.value}`} style={{ color: '#ff9800', textDecoration: 'none' }}>
          {params.value}
        </a>
      )
    },
    { field: 'quantity', headerName: 'Quantity', width: 120 },
    { field: 'type', headerName: 'Type', width: 120 },
  ];
  
  export const incomingStockColumns = [
    { field: 'procureNumber', headerName: 'Procure Number', width: 150 },
    { field: 'date', headerName: 'Date', width: 120 },
    { field: 'expectedArrival', headerName: 'Expected Arrival', width: 150 },
    { field: 'quantity', headerName: 'Quantity', width: 120 },
  ];
  
  export const getTotalQuantity = () => {
    return warehouseQuantities.reduce((sum, wh) => sum + wh.quantity, 0);
  };
  
  export const getTotalIncomingQuantity = () => {
    return incomingStock.reduce((sum, stock) => sum + stock.quantity, 0);
  };

  export const isUserAuthorized = () => {
    // This is a placeholder function. In a real application, you would implement
    // actual authorization logic here.
    return true;
  };