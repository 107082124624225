// src/components/ExpenseDashboard/expenseColumns.js
import React from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { formatCurrency } from '../../utils/currencyUtils';

export const createExpenseColumns = (handleUpdate, handleDeleteConfirm) => [
  { field: 'date', headerName: 'Date', width: 150 },
  { field: 'id', headerName: 'Expense Number', width: 150 },
  { field: 'category_name', headerName: 'Category', width: 150 },
  { 
    field: 'amount', 
    headerName: 'Total Amount', 
    width: 150,
    valueFormatter: (params) => formatCurrency(params),
  },
  { field: 'description', headerName: 'Description', width: 200 },
  { field: 'account_name', headerName: 'Account', width: 150 },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: (params) => (
      <>
        <Button onClick={() => handleUpdate(params.row)}><EditIcon /></Button>
        <Button onClick={() => handleDeleteConfirm(params.row.id)}><DeleteIcon /></Button>
      </>
    ),
  },
];