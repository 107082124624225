import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_TOKEN = process.env.REACT_APP_ADA_TOKEN;
const API_TOKEN = localStorage.getItem('token'); // Get token from localStorage

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Token ${API_TOKEN}`,
  },
});

const axiosFileInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Token ${API_TOKEN}`,
  },
});

export const API_ENDPOINTS = {
  login: '/',
  users:'/api/users/',
  token: '/api/token/',
  orders: '/api/orders/',
  products: '/api/products/',
  expense: '/api/expenses/',
  expensecat: '/api/expense-categories/',
  accounts: '/api/accounts/',
  warehouses: '/api/warehouses/',
  stocktransfer:'/api/stock-transfers/',
  stockadjustment:'/api/stock-adjustments/',
  purchaseOrders: '/api/purchasing/',
  customers: '/api/customers/',
  transactions: '/api/transactions/',
  transactionNumbers: '/api/transaction-numbers/',
  categoryTags: '/api/category-tags/',
  inventory: '/api/inventory/',
};

export const API_IMPORT_ENDPOINTS = {
  products: '/api/import/products/',
  expense: '/api/import/expenses/',
  orders: '/api/import/orders/',
  accounts: '/api/import/accounts/',
  warehouses: '/api/import/warehouses/',
  stocktransfer:'/api/import/stock-transfers/',
  stockadjustment:'/api/import/stock-adjustments/',
  purchaseOrders: '/api/import/purchasing/',
  customers: '/api/customers/',
  inventory: '/api/inventory/',
};

export { axiosInstance, axiosFileInstance };