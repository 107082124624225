import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Button,
  CircularProgress
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import { inventoryService } from '../services/inventoryService';
import { productService } from '../services/productService'; 
import {
  financialInfo,
  stockTransactions,
  incomingStock,
  salesTrend,
  stockTransactionColumns,
  incomingStockColumns,
  getTotalQuantity,
  getTotalIncomingQuantity,
  isUserAuthorized
} from '../utils/productUtils';
import { useAuth } from '../contexts/AuthContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
});

const LabeledValue = ({ label, value }) => (
  <Box>
    <Typography component="span" variant="body2" fontWeight="bold">{label}: </Typography>
    <Typography component="span" variant="body1">{value}</Typography>
  </Box>
);

const SectionHeader = ({ children }) => (
  <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const ProductDetailView = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const [expandedIncoming, setExpandedIncoming] = useState(false);
  const [productInfo, setProductInfo] = useState({
    sku: '',
    name: '',
    dateCreated: '',
    lastReceived: ''
  });
  const [inventories, setInventories] = useState();
  const authorized = isUserAuthorized();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const inventories = await inventoryService.fetchInventoryByProduct(id);
        const product_info = await productService.fetchProduct(id);
        // const transaction = await inventoryService.fetchMovement(id);
        // console.log("Inventories is:",inventories);
        const warehouseQuantities = inventories.map(inventory => ({
          id: inventory.warehouse.id,
          name: inventory.warehouse.name,
          quantity: inventory.quantity_on_hand
        }));
        setInventories(warehouseQuantities);
        setProductInfo(product_info);
        // console.log("Product info is: ",product_info);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // console.log("Inventories is: ", inventories);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, p: 3, backgroundColor: 'background.default' }}>
        <Container maxWidth="xl">
          <Typography variant="h4" gutterBottom color="primary">
            {productInfo.sku} - {productInfo.name} (Total Quantity: {productInfo.quantity})
          </Typography>

          <Grid container spacing={3}>
            {/* Basic Information */}
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <SectionHeader>Basic Information</SectionHeader>
                  <Button startIcon={<EditIcon />} variant="outlined" color="primary" size="small">
                    Edit
                  </Button>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}><LabeledValue label="SKU" value={productInfo.sku} /></Grid>
                  <Grid item xs={12}><LabeledValue label="Name" value={productInfo.name} /></Grid>
                  <Grid item xs={12}><LabeledValue label="Date Created" value={productInfo.dateCreated} /></Grid>
                  <Grid item xs={12}><LabeledValue label="Last Received" value={productInfo.lastReceived} /></Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Financial Information */}
            {user.role === 'ADMIN' && (
              <Grid item xs={12} md={6} lg={4}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <SectionHeader>Financial Information</SectionHeader>
                  <Grid container spacing={2}>
                    <Grid item xs={12}><LabeledValue label="Buy Price" value={`$${financialInfo.buyPrice}`} /></Grid>
                    <Grid item xs={12}><LabeledValue label="Sales Price" value={`$${financialInfo.salesPrice}`} /></Grid>
                    <Grid item xs={12}><LabeledValue label="Buy Account" value={financialInfo.buyAccount} /></Grid>
                    <Grid item xs={12}><LabeledValue label="Sales Account" value={financialInfo.salesAccount} /></Grid>
                    <Grid item xs={12}><LabeledValue label="Average Price" value={`$${financialInfo.averagePrice}`} /></Grid>
                    <Grid item xs={12}><LabeledValue label="Profit Margin" value={`${financialInfo.profitMargin}%`} /></Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}

            {/* Warehouse Quantities */}
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: 2, height: '100%' }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Warehouse</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inventories.map((wh) => (
                            <TableRow key={wh.id}>
                              <TableCell component="th" scope="row">
                                {wh.name}
                              </TableCell>
                              <TableCell align="right">{wh.quantity}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

              </Paper>
            </Grid>

            {/* Incoming Stock */}
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <SectionHeader>Incoming Stock</SectionHeader>
                <Typography>Total Incoming Quantity: {getTotalIncomingQuantity()}</Typography>
                <Accordion expanded={expandedIncoming} onChange={() => setExpandedIncoming(!expandedIncoming)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Incoming Stock Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ height: 300, width: '100%' }}>
                      <DataGrid rows={incomingStock} columns={incomingStockColumns} pageSize={5} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>

            {/* Stock Transactions */}
            <Grid item xs={12} lg={8}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <SectionHeader>Stock Transactions</SectionHeader>
                <div style={{ height: 400, width: '100%' }}>
                  <DataGrid rows={stockTransactions} columns={stockTransactionColumns} pageSize={5} />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ProductDetailView;