import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { AttachMoney, TrendingUp, MoneyOff } from '@mui/icons-material';
import InfoCard from '../components/common/InfoCard';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useAuth } from '../contexts/AuthContext';

const Monitor = () => {
  useDocumentTitle('Monitor');
  const { user, login, logout } = useAuth();

  console.log("Users is: ", user);
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Monitor
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <InfoCard
            title="Total Revenue"
            value="$50,000"
            subtitle="This month"
            icon={AttachMoney}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Monitor;