import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProductDashboard from './pages/ProductDashboard';
import PurchaseForm from './pages/PurchaseForm';
import TopBar from './pages/TopBar';
import SideBar from './pages/SideBar';
import { CssBaseline, Toolbar, Box, ThemeProvider, CircularProgress } from '@mui/material';
import theme from './theme/theme';
import SalesDashboard from './pages/SalesDashboard';
import SalesForm from './pages/SalesForm';
import SalesDetailView from './pages/SalesDetailView';
import SalesDetailEdit from './pages/SalesDetailEdit';
import ExpenseDashboard from './pages/ExpenseDashboard';
import Settings from './pages/Settings';
import MainDashboard from './pages/MainDashboard';
import PurchaseDashboard from './pages/PurchaseDashboard';
import PurchaseDetailView from './pages/PurchaseDetailView';
import PurchaseDetailEdit from './pages/PurchaseDetailEdit';
import AccountDashboard from './pages/AccountDashboard';
import ContactDashboard from './pages/ContactDashboard';
import ProductDetailView from './pages/ProductDetailView';
import ProductForm from './pages/ProductForm';
import LoginForm from './pages/LoginForm';
import WarehouseDashboard from './pages/WarehouseDashboard';
import WarehouseTransferForm from './pages/WarehouseTransferForm';
import WarehouseLocationForm from './pages/WarehouseLocationForm';
import StockAdjustmentForm from './pages/StockAdjustmentForm';
import UnauthorizedPage from './pages/UnauthorizedPage';
import Monitor from './pages/Monitor';
import { IntlProvider } from 'react-intl';
import translations from './translations';
import WarehouseTransferDetailView from './pages/WarehouseTransferDetailView';
import WarehouseTransferDetailEdit from './pages/WarehouseTransferDetailEdit';
import AuthProvider, { useAuth } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

const drawerWidth = 240;

function AppContent() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { isLoggedIn, isLoading, login, logout, user } = useAuth();
  const [language, setLanguage] = useState('en');
  console.log("IsLoggidn: ", isLoggedIn);
  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <IntlProvider messages={translations[language]} locale={language} defaultLocale="en">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box sx={{ display: 'flex' }}>
            {isLoggedIn && (
              <>
                <TopBar handleDrawerToggle={handleDrawerToggle} isOpen={isDrawerOpen} onLogout={logout} />
                <SideBar isOpen={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} onLogout={logout}/>
              </>
            )}
            <Box component="main" sx={{ 
              flexGrow: 1, 
              p: 3, 
              transition: 'margin-left 0.3s',
              marginLeft: isLoggedIn && isDrawerOpen ? `${drawerWidth}px` : '0px', 
            }}>
              {isLoggedIn && <Toolbar />}
              <Routes>
              <Route path="/login" element={<LoginForm onLogin={login} />}/>
              <Route path="/" element={<ProtectedRoute element={<MainDashboard />} requiredRole={['ADMIN','ACC', 'WH','EMP']} redirectTo="/login"/>}/>
              <Route path="/monitor" element={<ProtectedRoute element={<Monitor />} requiredRole={['ADMIN']} redirectTo="/login"/>}/>
              <Route path="/purchase/new" element={<ProtectedRoute element={<PurchaseForm />} requiredRole={['ADMIN', 'ACC']} />} />
              <Route path="/purchase/:id" element={<ProtectedRoute element={<PurchaseDetailView />} requiredRole={['ADMIN', 'ACC']} />} />
              <Route path="/purchase/:id/edit" element={<ProtectedRoute element={<PurchaseDetailEdit />} requiredRole={['ADMIN', 'ACC']} />} />
              <Route path="/account" element={<ProtectedRoute element={<AccountDashboard />} requiredRole={['ADMIN', 'ACC']} />} />
              <Route path="/product" element={<ProtectedRoute element={<ProductDashboard />} requiredRole={['ADMIN','EMP','ACC', 'WH']} />} />
              <Route path="/product/:id" element={<ProtectedRoute element={<ProductDetailView />} requiredRole={['ADMIN', 'SPV', 'WH']} />} />
              <Route path="/product/new" element={<ProtectedRoute element={<ProductForm />} requiredRole={['ADMIN', 'SPV']} />} />
              <Route path="/purchase" element={<ProtectedRoute element={<PurchaseDashboard />} requiredRole={['ADMIN', 'ACC']} />} />
              <Route path="/sales" element={<ProtectedRoute element={<SalesDashboard />} requiredRole={['ADMIN', 'SPV', 'EMP','ACC']} />} />
              <Route path="/sales/:id" element={<ProtectedRoute element={<SalesDetailView />} requiredRole={['ADMIN', 'SPV', 'EMP','ACC']} />} />
              <Route path="/sales/:id/edit" element={<ProtectedRoute element={<SalesDetailEdit />} requiredRole={['ADMIN','SPV', 'EMP','ACC']} />} />
              <Route path="/sales/new" element={<ProtectedRoute element={<SalesForm />} requiredRole={['ADMIN', 'SPV','EMP','ACC']} />} />
              <Route path="/expense" element={<ProtectedRoute element={<ExpenseDashboard />} requiredRole={['ADMIN', 'ACC']} />} />
              <Route path="/settings" element={<ProtectedRoute element={<Settings />} requiredRole={['ADMIN']} />} />
              <Route path="/contact" element={<ProtectedRoute element={<ContactDashboard />} requiredRole={['ADMIN', 'SPV', 'EMP']} />} />
              <Route path="/warehouse" element={<ProtectedRoute element={<WarehouseDashboard />} requiredRole={['ADMIN','SPV', 'WH', 'EMP']} />} />
              <Route path="/warehouse/transfer" element={<ProtectedRoute element={<WarehouseTransferForm />} requiredRole={['ADMIN', 'SPV','WH', 'ACC']} />} />
              <Route path="/warehouse/transfer/:id" element={<ProtectedRoute element={<WarehouseTransferDetailView />} requiredRole={['ADMIN', 'SPV','WH', 'ACC']} />} />
              <Route path="/warehouse/transfer/:id/edit" element={<ProtectedRoute element={<WarehouseTransferDetailEdit />} requiredRole={['ADMIN','SPV', 'WH', 'ACC']} />} />
              <Route path="/warehouse/adjustment" element={<ProtectedRoute element={<StockAdjustmentForm />} requiredRole={['ADMIN', 'SPV','WH', 'ACC']} />} />
              <Route path="/warehouse/location" element={<ProtectedRoute element={<WarehouseLocationForm />} requiredRole={['ADMIN', 'SPV','WH', 'ACC']} />} />
              <Route path="/unauthorized" element={<UnauthorizedPage />} />
              </Routes>
            </Box>
          </Box>
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;