import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Box,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { warehouseService } from '../services/warehouseService';
import { productService } from '../services/productService';
import { customerService } from '../services/customerService';
import { orderService } from '../services/orderService';
import { commonService } from '../services/commonService';
import { accountService } from '../services/accountService';
import { formatCurrency } from '../utils/currencyUtils';
import useSessionStorage from '../hooks/useSessionStorage';
import useDebounce from '../hooks/useDebounce';

const schema = yup.object().shape({
  order_number: yup.string().required('Sales number is required'),
  customer_id: yup.object().nullable().required('Customer is required'),
  order_date: yup.date().required('Date is required'),
  warehouse_id: yup.object().nullable().required('Warehouse is required'),
  deposit_account: yup.object().nullable().required('Deposit account is required'),
  memo: yup.string(),
  items: yup.array().of(
    yup.object().shape({
      quantity: yup.number().positive('Quantity must be positive').integer('Quantity must be a whole number').required('Quantity is required'),
      sale_price: yup.number().positive('Sales Price must be positive').required('Sales Price is required'),
    })
  ).min(1, 'At least one item is required')
});

const SalesForm = () => {
  const navigate = useNavigate();
  
  const [warehouses, setWarehouses] = useSessionStorage('warehouses', []);
  const [products, setProducts] = useSessionStorage('products_sales', []);
  const [customers, setCustomers] = useSessionStorage('customers', []);
  const [depositAccounts, setDepositAccounts] = useSessionStorage('depositAccounts', []);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 300);
  const MIN_SEARCH_CHARS = 3;

  const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      order_number: '',
      customer_id: null,
      order_date: dayjs(),
      warehouse_id: null,
      deposit_account: null,
      memo: '',
      items: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items'
  });

  useEffect( () => {
      fetchInputProducts();
  },[debouncedInputValue]);

  const fetchInputProducts = async () => {
    if (debouncedInputValue.length >= MIN_SEARCH_CHARS) {
      try {
        const data = await productService.searchProducts(debouncedInputValue);
        const formattedData = data.map(product => ({
          ...product,
          formattedSalePrice: formatCurrency(product.sale_price),
        }));
        setProducts(formattedData);

        console.log("Formatted data is: ",formattedData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // const [warehousesData, productsData, customersData, depositAccountsData, nextNumber] = await Promise.all([
        //   warehouseService.fetchAllWarehouses(),
        //   productService.fetchAllProductsForOrderForm(),
        //   customerService.fetchAllCustomers(),
        //   accountService.fetchAccountsByCategory('Kas & Bank'),
        //   commonService.getNextTransactionNumber('ORDER')
        // ]);
        const nextNumber = await commonService.getNextTransactionNumber('ORDER');
        let warehousesData = warehouses;
        let productsData = products;
        let customersData = customers;
        let depositAccountsData = depositAccounts;

        if (warehouses.length === 0) {
          warehousesData = await warehouseService.fetchAllWarehouses();
          setWarehouses(warehousesData);
        }
        if (products.length === 0) {
          productsData = await productService.fetchAllProductsForOrderForm();
          setProducts(productsData);
        }
        if (customers.length === 0) {
          customersData = await customerService.fetchAllCustomers();
          setCustomers(customersData);
        }
        if (depositAccounts.length === 0) {
          depositAccountsData = await accountService.fetchAccountsByCategory('Kas & Bank');
          setDepositAccounts(depositAccountsData);
        }

        console.log('Product data', productsData);
        // setWarehouses(warehousesData);
        // setProducts(productsData);
        // setCustomers(customersData);
        // setDepositAccounts(depositAccountsData.results);

        setValue('order_number', nextNumber);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbarMessage('Error fetching initial data. Please try again.');
        setOpenSnackbar(true);
      } finally {
        setIsLoading(false);
      }
      
    };
    fetchData();
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      console.log('Form data before submission:', data); // Log the form data

      const newOrderNumber = await commonService.incrementTransactionNumber('ORDER');
      const orderData = {
        order_number: newOrderNumber,
        order_date: dayjs(data.order_date).format('YYYY-MM-DD'),
        customer: data.customer_id.id,
        warehouse: data.warehouse_id.id,
        account: data.deposit_account.id,
        user: 1, 
        memo: data.memo,
        items: data.items.map(({ id, quantity, sale_price }) => ({ 
          product: id.id, 
          quantity, 
          price: sale_price
        })),
        status: 'completed',
        shipping_address: 'Nothing', // TODO: Add shipping address field to the form
        total: data.items.reduce((sum, item) => sum + (item.quantity * item.sale_price), 0),
      };

      console.log('Order data being sent to server:', orderData); // Log the order data

      const response = await orderService.createOrder(orderData);
      console.log('Server response:', response); // Log the server response

      setSnackbarMessage('Sales form submitted successfully!');
      setOpenSnackbar(true);
      navigate(`/sales`);
      // Reset form here if needed
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      setSnackbarMessage('Error submitting form. Please try again.');
      setOpenSnackbar(true);
      navigate('/sales/');
    }
  };

  const items = watch('items');
  const total = items.reduce((sum, item) => sum + (item.quantity * item.sale_price), 0);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Sales Form
          </Typography>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="order_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="Date"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!errors.order_date}
                            helperText={errors.order_date?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="order_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Sales Number"
                        fullWidth
                        error={!!errors.order_number}
                        helperText={errors.order_number?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="customer_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={customers}
                        getOptionLabel={(option) => option.full_name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer"
                            error={!!errors.customer_id}
                            helperText={errors.customer_id?.message}
                          />
                        )}
                        onChange={(_, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="warehouse_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={warehouses}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Warehouse"
                            error={!!errors.warehouse_id}
                            helperText={errors.warehouse_id?.message}
                          />
                        )}
                        onChange={(_, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="deposit_account"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={depositAccounts}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Deposit Account"
                            error={!!errors.deposit_account}
                            helperText={errors.deposit_account?.message}
                          />
                        )}
                        onChange={(_, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="memo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Memo"
                        multiline
                        rows={3}
                        fullWidth
                        error={!!errors.memo}
                        helperText={errors.memo?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" component="h2">
                Item Details
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => append({ product_id: null, quantity: 1, sale_price: '' })}
              >
                Add Item
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="40%">Item SKU</TableCell>
                    <TableCell width="15%">Quantity</TableCell>
                    <TableCell width="15%">Price</TableCell>
                    <TableCell width="20%">Total</TableCell>
                    <TableCell width="10%">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((field, index) => (
                    <TableRow key={field.id}>
                      <TableCell>
                        <Controller
                          name={`items.${index}.id`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              options={products}
                              getOptionLabel={(option) => option ? `${option.sku} - ${option.name}` : ''}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              onInputChange={(_, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!!errors.items?.[index]?.product}
                                      helperText={errors.items?.[index]?.product?.message}
                                  />
                              )}
                              onChange={(_, value) => {
                                field.onChange(value);
                                if (value) {
                                  setValue(`items.${index}.sale_price`, value.sale_price);
                                  setValue(`items.${index}.quantity`, 1);
                                  setValue(`items.${index}.product_id`, value.product_id);
                                }
                              }}
                              value={field.value || null}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`items.${index}.quantity`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              size="small"
                              fullWidth
                              inputProps={{ style: { width: '100%' } }}
                              error={!!errors.items?.[index]?.quantity}
                              helperText={errors.items?.[index]?.quantity?.message}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`items.${index}.sale_price`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              size="small"
                              fullWidth
                              inputProps={{ style: { width: '100%' } }}
                              error={!!errors.items?.[index]?.sale_price}
                              helperText={errors.items?.[index]?.sale_price?.message}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {formatCurrency((items[index]?.quantity || 0) * (items[index]?.sale_price || 0))}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => remove(index)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Total Amount: {formatCurrency(total)}
              </Typography>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
        
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default SalesForm;